<template>
  <div class="mx-4 my-4 md:mx-8">
    <Breadcrumb :links="breadcrumb" />
    <br />
    <h1 class="text-3xl mb-5">Rastrear Envio</h1>
    <div class="grid grid-cols-12 gap-3 mb-2">
      <div class="col-span-12 md:col-span-3">
        <label for="busca" class="block text-sm font-medium text-gray-700">Telefone Contato</label>
        <input
          v-model="telefone"
          type="text"
          name="busca"
          placeholder="Buscar contato ou COD CLIENTE"
          class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
        />
      </div>
      <div class="col-span-12 md:col-span-2">
        <button
          @click="start"
          type="button"
          class="md:mt-7 bg-gray-500 hover:bg-gray-600 focus:bg-gray-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-md text-sm shadow-sm hover:shadow-md w-full font-semibold text-center"
        >
          <span class="inline-block mr-2">Buscar</span>
        </button>
      </div>
    </div>
    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Número</th>
                  <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Campanha</th>
                  <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID Custom</th>
                  <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Centro de Custo</th>
                  <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Mensagem</th>
                  <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Tipo</th>
                  <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Enviou</th>
                  <th v-if="$store.state.user.acessos_lzv2 && $store.state.user.acessos_lzv2.indexOf('whatsappApiOficial') === -1" scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Tem Zap</th>
                  <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Data</th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="item in list" :key="item._id">
                  <td class="text-sm 2xl:text-base px-2 py-3 whitespace-nowrap">
                    {{ item.telefone ? item.telefone : "" }}
                  </td>
                  <td class="text-sm 2xl:text-base px-2 py-3 whitespace-nowrap">
                    {{ item.campanha ? item.campanha : "" }}
                  </td>
                  <td class="text-sm 2xl:text-base px-2 py-3 whitespace-nowrap">
                    {{ item.idCustom ? item.idCustom : "" }}
                  </td>
                  <td class="text-sm 2xl:text-base px-2 py-3 whitespace-nowrap">
                    {{ item.centrocusto ? item.centrocusto : "" }}
                  </td>
                  <td class="text-sm 2xl:text-base px-2 py-3" :title="item.mensagem" @dblclick="copy(item.mensagem)">
                    {{ item.mensagem && item.mensagem.length > 30 ? `${item.mensagem.slice(0, 30)}...` : item.mensagem }}
                    <a
                      class="flex"
                      v-if="item.arquivo && item.arquivo.filename"
                      target="_blank"
                      className="click-icon"
                      :href="`${api}/upload?mimetype=${item.arquivo.mimetype}&filename=${item.arquivo.filename}&folder=arquivosWhats`"
                    >
                      <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path
                          fill-rule="evenodd"
                          d="M12.586 4.586a2 2 0 112.828 2.828l-3 3a2 2 0 01-2.828 0 1 1 0 00-1.414 1.414 4 4 0 005.656 0l3-3a4 4 0 00-5.656-5.656l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5zm-5 5a2 2 0 012.828 0 1 1 0 101.414-1.414 4 4 0 00-5.656 0l-3 3a4 4 0 105.656 5.656l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a2 2 0 11-2.828-2.828l3-3z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                      <span>Arquivo</span>
                    </a>
                  </td>
                  <td class="text-sm 2xl:text-base px-2 py-3">
                    {{ !item.validacao ? "Envio" : "Validação" }}
                  </td>
                  <td class="text-sm 2xl:text-base px-2 py-3">
                    {{ item.enviou ? "SIM" : "NÃO" }}
                  </td>
                  <td v-if="$store.state.user.acessos_lzv2 && $store.state.user.acessos_lzv2.indexOf('whatsappApiOficial') === -1" class="text-sm 2xl:text-base px-2 py-3">
                    {{ item.temzap ? "SIM" : "NÃO" }}
                  </td>
                  <td class="text-sm 2xl:text-base px-2 py-3">
                    <span v-if="item.data">
                      {{ item.data | moment("DD/MM/YYYY HH:mm") }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "../../components/Breadcrumbs.vue";

export default {
  components: {
    Breadcrumb
  },
  data() {
    return {
      breadcrumb: [{ url: "/rastrear", value: "Rastrear envio" }],
      telefone: "",
      list: [],
      total: 0
    };
  },
  methods: {
    async start() {
      if (!this.telefone) return this.$vToastify.error("Telefone é obrigatório");

      let centrocusto;
      if (this.$store.state.user.centrocusto && !this.$store.state.user.admin) {
        centrocusto = this.$store.state.user.centrocusto;
      }

      const dataReq = await this.$http.post("/v1/campanhasLZV2/consulta-numero-api", { numero: this.telefone, centrocusto });
      this.list = dataReq.data.data;
      this.total = dataReq.data.total;
    },

    copy(text) {
      navigator.clipboard.writeText(text);
    }
  }
};
</script>
